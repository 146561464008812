import { ApiRequest } from '@sancare/ui-frontend-commons/src/misc/api-request'
import dayjs from '@sancare/ui-frontend-commons/src/misc/dayjs'
import { MedicalUnit } from '@sancare/ui-frontend-commons/src/store/modules/filters/medical-unit-filter/types'
import { AbstractState } from '@sancare/ui-frontend-commons/src/store/types'
import { HealthDataDocument } from '@sancare/ui-frontend-commons/src/types/health-data'
import { Dayjs } from 'dayjs'

import { CodingSelection } from '@/stay-displayer/types'
import { Diagnosis } from '@/store/modules/diagnosis/types'
import { BaseAct, BaseCodedAct, CodedMedicalAct, MedicalAct, RawCodedMedicalAct } from '@/store/modules/medical-act/types'
import { SsrGroupingResult } from '@/store/modules/stay/ssr/grouping/types'
import { BaseSummaryUnit, RawStay, Stay } from '@/store/modules/stay/types'

export type SsrDiagnosis = Diagnosis & {
  canBeFpp?: boolean
  canBeMmp?: boolean
  canBeAe?: boolean
  canBeDas?: boolean
}

export type SsrAssociatedDiagnosis = {
  associatedDiagnosis: SsrDiagnosis
  id?: number
}

export type SsrDiagnosisGroup = {
  fpp: SsrAssociatedDiagnosis
  mmp: SsrAssociatedDiagnosis
  ae: SsrAssociatedDiagnosis
  dasList: SsrAssociatedDiagnosis[]
  groupingResult: SsrGroupingResult
}

export type RehabilitationAct = BaseAct
export type CodedRehabilitationAct = Pick<BaseCodedAct<unknown, RehabilitationAct>, 'id' | 'associatedAct' | 'disabled'>

export interface Dependencies {
  depDressing: number
  depMoving: number
  depFeeding: number
  depContinence: number
  depBehaviour: number
  depSocial: number
}

export type BaseRhs<TDate, TAct extends BaseAct, TCodedAct extends BaseCodedAct<TDate, TAct>>
  = BaseSummaryUnit<TDate, TAct, TCodedAct> & Dependencies & {
    medicalUnit: MedicalUnit
    weekDays: string
    weekEndDays: string
    initialModeDiagnosisGroup: SsrDiagnosisGroup
    currentModeDiagnosisGroup: SsrDiagnosisGroup
    codedRehabilitationActs: CodedRehabilitationAct[]
    surgeryDate: TDate
    // TODO: To be completed depending on serializer schema
  }

export type RhsRefreshedGroupingResult = {
  id: number
  stay: {
    id: number
    standardModeGroupingResult: SsrGroupingResult
  }
  currentModeDiagnosisGroup: {
    id: number
    groupingResult: SsrGroupingResult
  }
}

export type RawRhs = BaseRhs<string, MedicalAct, RawCodedMedicalAct> & {
  weekNb: string
}
export type Rhs = BaseRhs<Dayjs, MedicalAct, CodedMedicalAct> & {
  week: Week
}

interface Week {
  nb: number
  year: number
}

interface BaseSsrStay<TRhs> {
  ssrId: number
  hospitalizationType: HospitalizationType
  rhsList: TRhs[]
  presenceDayCount: number
  firstStandardModeGroupingResult: SsrGroupingResult
  standardModeGroupingResult: SsrGroupingResult
  // TODO: To be completed depending on serializer schema
}
export type RawSsrStay = RawStay & BaseSsrStay<RawRhs>
export type SsrStay = Stay & BaseSsrStay<Rhs>

// todo move in test data
export const defaultRhs = (): Rhs => {
  return {
    id: null,
    codedMedicalActs: [],
    codedRehabilitationActs: [],
    predictedLabels: [],
    medicalUnit: null,
    weekDays: '00000',
    endDate: dayjs(),
    startDate: dayjs(),
    week: {
      nb: 1,
      year: 0
    },
    weekEndDays: '00',
    depDressing: 0,
    depMoving: 0,
    depFeeding: 0,
    depContinence: 0,
    depBehaviour: 0,
    depSocial: 0,
    surgeryDate: null,
    initialModeDiagnosisGroup: defaultSsrDiagnosisGroup(),
    currentModeDiagnosisGroup: defaultSsrDiagnosisGroup(),
  }
}

export const defaultMainContent = (): HealthDataDocument => {
  return {
    type: null,
    targetId: 0,
    drugEventPrestationType: null
  }
}

const defaultSsrDiagnosisGroup = (): SsrDiagnosisGroup => {
  return {
    fpp: defaultSsrDiagnosis(),
    mmp: defaultSsrDiagnosis(),
    ae: defaultSsrDiagnosis(),
    dasList: [],
    groupingResult: {
      gme: null,
      gmt: null,
      value: null,
    }
  }
}

const defaultSsrDiagnosis = (): SsrAssociatedDiagnosis => {
  return {
    associatedDiagnosis : {
      reference: null,
      canBeFpp: true,
      canBeMmp: true,
      canBeAe: true,
      canBeDas: true,
    }
  }
}

const baseUrl = '/api'
export class SsrStayState extends AbstractState {
  rawStay: RawSsrStay
  currentStay: SsrStay | null = null
  rawRhs: RawRhs
  currentRhs: Rhs | null = null
  mainContent: HealthDataDocument = defaultMainContent()
  justificationSelection: CodingSelection | null = null
  justificationShouldScroll: boolean = false
  isGroupingResultRefreshing: boolean = false

  constructor() {
    super()
    this.requests = {
      fetchStay: new ApiRequest('fetchStay', 'GET', `${baseUrl}/stays-ssr/{id}`),
      fetchRhs: new ApiRequest('fetchRhs', 'GET', `${baseUrl}/rhs/{id}`),
      updateStayStatus: new ApiRequest('updateStayStatus', 'POST', `${baseUrl}/stays-ssr/update-status`),
      updateRhsDependency: new ApiRequest('updateRhsDependency', 'PUT', `${baseUrl}/rhs/{id}/dependency`)
    }
  }
}

export enum HospitalizationType {
  COMPLETE = 1,
  PARTIAL_DAY = 2,
  PARTIAL_NIGHT = 3,
  AMBULATORY = 4,
}

export enum SsrLoadingErrorReason {
  SSR_STAY_NOT_FOUND = 'SSR_STAY_NOT_FOUND',
  SSR_STAY_OTHER = 'SSR_STAY_OTHER'
}

export enum RhsLoadingErrorReason {
  RHS_NOT_FOUND = 'RHS_NOT_FOUND',
  RHS_OTHER = 'RHS_OTHER',
  RHS_UNKNOWN = 'RHS_UNKNOWN'
}

export enum RhsDashboardType {
  RHS_DASHBOARD_DIAGNOSES = 'RHS_DASHBOARD_DIAGNOSES',
  RHS_DASHBOARD_DEPENDENCIES = 'RHS_DASHBOARD_DEPENDENCIES',
  RHS_DASHBOARD_ACT_CCAM = 'RHS_DASHBOARD_ACT_CCAM',
  RHS_DASHBOARD_ACT_CSARR = 'RHS_DASHBOARD_ACT_CSARR'
}

export const RhsDashboardTitle = {
  [RhsDashboardType.RHS_DASHBOARD_DIAGNOSES]: 'Diagnostics CIM10',
  [RhsDashboardType.RHS_DASHBOARD_DEPENDENCIES]: 'Dépendances',
  [RhsDashboardType.RHS_DASHBOARD_ACT_CCAM]: 'Actes CCAM',
  [RhsDashboardType.RHS_DASHBOARD_ACT_CSARR]: 'Actes CSARR',
}

export const RhsDashboardClass = {
  [RhsDashboardType.RHS_DASHBOARD_DIAGNOSES]: 'dashboard-nosology',
  [RhsDashboardType.RHS_DASHBOARD_DEPENDENCIES]: 'dashboard-weight',
  [RhsDashboardType.RHS_DASHBOARD_ACT_CCAM]: 'dashboard-rehabilitation',
  [RhsDashboardType.RHS_DASHBOARD_ACT_CSARR]: 'dashboard-rehabilitation',
}

export const RhsDashboardButtonLabel = {
  [RhsDashboardType.RHS_DASHBOARD_DIAGNOSES]: 'Ajouter un diagnostic',
  [RhsDashboardType.RHS_DASHBOARD_ACT_CCAM]: 'Ajouter un acte',
  [RhsDashboardType.RHS_DASHBOARD_ACT_CSARR]: 'Ajouter un acte',
}

export enum DependenciesSections {
  PHYSICAL = 'physical',
  COGNITIVE = 'cognitive'
}

export enum DependenciesType {
  DRESSING = 'depDressing',
  MOVING = 'depMoving',
  FEEDING = 'depFeeding',
  CONTINENCE = 'depContinence',
  BEHAVIOR = 'depBehaviour',
  SOCIAL = 'depSocial'
}

export const DependenciesSectionsLabels = {
  [DependenciesSections.PHYSICAL]: 'Physique',
  [DependenciesSections.COGNITIVE]: 'Cognitif',
}

export const DependenciesLabels = {
  [DependenciesSections.PHYSICAL]: {
    [DependenciesType.DRESSING]: 'Habillage',
    [DependenciesType.MOVING]: 'Déplacement',
    [DependenciesType.FEEDING]: 'Alimentation',
    [DependenciesType.CONTINENCE]: 'Continence'
  },
  [DependenciesSections.COGNITIVE]: {
    [DependenciesType.BEHAVIOR]: 'Comportement',
    [DependenciesType.SOCIAL]: 'Communication'
  }
}

export type RhsDependencyElement = {
  dependencyType: string
  value: number
}
